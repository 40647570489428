<template>
	<div class="page">
		<PageTitle title="佣金提现设置"></PageTitle>
    <div class="page-content">
      <div class="block">
        <div class="header">
          <span>佣金提现门槛</span>
        </div>
        <div class="block-content">
          <span>每月可提现</span>
          <el-input placeholder="请输入" width="140px" v-model="config.max_month_count"></el-input>
          <span>次</span>
        </div>
        <div class="block-content">
          <span>佣金超过</span>
          <el-input placeholder="请输入" width="140px" v-model="config.min_apply_amount"></el-input>
          <span>元，可提现</span>
        </div>
        <span class="tips">注意：订单买家需要确定收货之后，佣金才能提现。付款成功以后生成佣金，但若订单未完成，则佣金退回。</span>
      </div>
      <div class="fix-confirm">
        <el-button type="primary" @click="saveConfig">确定</el-button>
      </div>
    </div>
	</div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
export default {
	name: 'SellerConfig',
	components: {
		PageTitle
	},
	data() {
		return {
      config:{
        
      }
    }
	},
	mounted() {
    this.getConfig()
	},
	methods:{
		async getConfig() {
      const url = 'admin/seller/config/detail'
      const data = await this.$https.get(url)
      if (!data) return
      data.min_apply_amount = data.min_apply_amount/100
      this.config = data
		},
    async saveConfig() {
      let params = {max_month_count: this.config.max_month_count, min_apply_amount: this.config.min_apply_amount*100}
      const url = 'admin/seller/config/save'
      const data = await this.$https.post(url, params)
      if (!data) return
      this.$message.success('保存成功')
    }
	}
}
</script>

<style scoped="scoped">
  .page-content {
    padding: 24px;
  }
  .block {
    width: 100%;
    background-color: white;
    padding-bottom: 24px;
  }
  .block .header {
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 32px;
  }
  .block .header span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0, 0.85);
  }
  .block .block-content {
    display: flex;
    padding: 24px 24px 0 24px;
    align-items: center;
  }
  .block .block-content .el-input {
    margin-left: 4px;
    margin-right: 4px;
    width: 140px;
  }
  .block .tips {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.45);
    padding: 24px 24px 0 24px;
  }
</style>
